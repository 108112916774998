import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import charts from './a.png'
export const About = () => {
  const theme = useTheme()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
        <Typography
          variant='h2'
          sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
        >
          ABOUT
        </Typography>

        <Stack sx={{ width: 700 }} spacing={6}>
          <Stack spacing={2}>
            <Typography variant='body1'>
              We have established <b>FBarchive</b> with two primary goals: (1)
              to provide researchers, journalists, and the public with
              searchable, indexed access to curated versions of the internal
              Facebook documents; and (2) to encourage collaborative analysis of
              the documents to improve understanding.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='body1'>
              <b>FBarchive</b> consists of images of documents taken by Frances
              Haugen, the whistleblower who disclosed tens of thousands of pages
              of internal Facebook research. Teams at Facebook used social
              science and data analytics to help the company's leadership
              understand adverse consequences from their decisions and
              technology designs. The documents provide a wealth of material for
              academic and journalistic study and a unique opportunity for
              collaborative investigation and understanding. We have removed
              personal names and other sensitive content that appear in the
              images for privacy reasons. We are unable to share the raw
              material as we believe it would be irresponsible to release images
              without this type of redaction and curation.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography variant='body1'>
              <b>FBarchive</b> is a research project across The Public Interest
              Tech Lab and Harvard Kennedy School’s Shorenstein Center on Media,
              Politics, and Public Policy. Our research aims are to construct an
              innovative tool for collaborative investigation of image
              artifacts, to curate content about the images for improved
              understanding, and to measure and assess the de-identification of
              personal names found in images. We welcome collaborators and
              participants.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
