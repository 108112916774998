import { useEffect, useState, useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Container,
  Box,
  Button,
  Paper,
  TextField,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Alert,
  AlertTitle,
} from '@mui/material'
import {
  Routes,
  Route,
  Outlet,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { motion } from 'framer-motion'
import $axios from '../../tools/axiosWrapper'
import { useForm } from 'react-hook-form'
import { randomUniform } from 'd3-random'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import LinkIcon from '@mui/icons-material/Link'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import _ from 'lodash'

export const Master = ({}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [results, setResults] = useState(null)

  useEffect(() => {
    $axios.get('/collection/list/green').then((d) => {
      setResults(
        Object.entries(d.data.images).map(([key, val]) => ({ key, ...val }))
      )
    })
  }, [])
  console.log(results)

  const COLUMNS = [
    {
      field: 'key',
      headerName: 'link',
      width: 280,
      resizable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props
        return (
          <>
            <Link
              className={'linktable'}
              to={'doc/' + value.replace('w32', '')}
            >
              <LinkIcon />
              <div>{'doc/' + value.replace('w32', '')}</div>
            </Link>
          </>
        )
      },
    },
    {
      field: 'title',
      headerName: 'document title',
      width: 550,
      resizable: true,
    },
    {
      field: 'images',
      headerName: 'images',
      width: 100,
      resizable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props
        return (
          <>
            <div>{value?.length}</div>
          </>
        )
      },
    },
  ]

  return (
    <>
      <Stack spacing={4} sx={{ my: 6, minHeight: '600px', mb: 20 }}>
        {results && (
          <>
            <Typography variant={'h6'}>Master List of Documents:</Typography>
            <Box sx={{ height: '600px' }}>
              {results && (
                <DataGridPremium
                  pagination
                  // checkboxSelection
                  initialState={{
                    pagination: { paginationModel: { pageSize: 820 } },
                    sorting: {
                      sortModel: [{ field: 'title', sort: 'asc' }],
                    },
                  }}
                  getRowId={(row) => row.key}
                  columns={COLUMNS}
                  rows={results}
                  rowHeight={40}
                />
              )}
            </Box>
          </>
        )}
      </Stack>
    </>
  )
}
