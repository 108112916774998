import { useEffect, useState, useContext, useMemo } from 'react'

import { Container, Grid, Typography, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
export const Faq = () => {
  const theme = useTheme()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Stack sx={{ minHeight: '100vh', py: 12 }} alignItems={'center'}>
        <Typography
          variant='h2'
          sx={{ fontWeight: 800, color: theme.palette.purple[5], mb: 8 }}
        >
          FREQUENTLY ASKED QUESTIONS
        </Typography>

        <Stack sx={{ width: 700 }} spacing={7}>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              What is the purpose of this project?
            </Typography>
            <Typography variant='body1'>
              FBarchive provides researchers, students, policymakers, and
              journalists around the world with searchable, indexed access to
              the internal Facebook documents that were leaked by whistleblower
              Frances Haugen. It is a first-of-its-kind platform for sorting,
              citing, and archiving visual images, and provides a tool for the
              collaborative investigation and research of the Facebook documents
              at a wider scale than any previous whistleblower leak. The design
              of the archive is motivated by the supposition that there is
              critical information within the documents to be explored and
              interrogated by researchers, journalists, and policymakers in an
              effort to bring more transparency to the practices of major tech
              corporations and the outsize impact they have on the world at
              large.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How will it be used?
            </Typography>
            <Typography variant='body1'>
              The FBarchive platform enables those working across different
              fields to utilize their varied backgrounds and expertise to study
              the leaked internal Facebook documents. The archive offers the
              ability to search and analyze these documents, perform research
              and inform public policy recommendations. Ultimately, we expect
              that FBarchive will aid companies, policymakers, and advocates in
              creating a safer, healthier, and more productive digital
              environment for the world.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How is the platform different from existing open-data technology
              solutions?
            </Typography>
            <Typography variant='body1'>
              FBarchive is designed to offer a more robust research experience
              compared to other open-data sources, like Gizmodo’s Facebook
              Papers Directory, which provides a gradual release of the
              documents grouped by topic. The Gizmodo format is designed for
              public consumption, but not systematic research and reference.
              FBarchive, on the other hand, is designed to facilitate research
              by providing access to the full collection of documents and a
              range of functionality to aid analysis. We have redacted the
              documents to protect individual identities, converted them to
              plain text, translated all non-English text, made the collection
              searchable and taggable, and developed a glossary to aid
              comprehension.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How will this project provide more insights than has been provided
              by journalists?
            </Typography>
            <Typography variant='body1'>
              The platform provides access to the Facebook documents to a much
              wider audience than the initial leak to journalists and it has
              been designed specifically to share with researchers,
              policymakers, and advocates who will all bring their unique
              perspectives and priorities to their encounters with the
              documents. This diversity of approaches will broaden the lessons
              learned and the impact of the documents beyond journalistic study
              alone. Additionally, FBarchive helps to verify the information
              contained in articles written about the documents. Prior to this
              curated collection, many journalists could not confirm what was
              actually in the images.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              What will be in the curated collection?
            </Typography>
            <Typography variant='body1'>
              The collection contains over 20,000 images of internal Facebook
              research reports, presentations, and online employee discussions,
              grouped into approximately 800 “documents.” Each image and
              document has been assigned a unique ID, and each document is
              assigned a title that summarizes its content. The images and
              documents are searchable and can be tagged and saved to personal
              collections to facilitate reference and research. Each image has
              been converted to plain text, but users can also see the original
              redacted image side-by-side with the plain text read-out. The
              documents cover a wide range of topics related to Facebook’s
              operations and impact.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How did you get the data?
            </Typography>
            <Typography variant='body1'>
              The data was provided to us via an anonymous drop.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How will you deal with concerns regarding privacy data and
              national/international security concerns?
            </Typography>
            <Typography variant='body1'>
              The images have been authenticated and the privacy of individuals
              featured in the images is protected. The Public Interest Tech Lab
              convened a Privacy and Safety Board composed of legal and privacy
              experts who provided best practices guidance on redactions. All
              names, identities, and personal images were redacted except in
              cases where they were associated with public figures (such as
              high-profile executives at Meta), or were included in posts
              intended to be shared publicly.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              Are there languages other than English represented in the content?
            </Typography>
            <Typography variant='body1'>
              The Facebook documents contained content in 24 languages other
              than English. The languages represented include:
              <Grid
                container
                rowSpacing={1}
                spacing={2}
                style={{
                  marginTop: '20px',
                  marginLeft: '-16px',
                  color: theme.palette.purple[5],
                }}
              >
                {[
                  'Amharic',
                  'Arabic',
                  'Assamese',
                  'Bengali',
                  'Burmese',
                  'Chinese',
                  'French',
                  'German',
                  'Greek',
                  'Hebrew',
                  'Hindi',
                  'Indonesian',
                  'Italian',
                  'Japanese',
                  'Korean',
                  'Oromo',
                  'Portuguese',
                  'Romanian',
                  'Russian',
                  'Spanish',
                  'Swedish',
                  'Telugu',
                  'Urdu',
                  'Vietnamese',
                ].map((d, i) => (
                  <Grid item>
                    <Typography sx={{ fontWeight: 800 }}>{d}</Typography>
                  </Grid>
                ))}
              </Grid>
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How does the search function work?
            </Typography>
            <Typography variant='body1'>
              It is a traditional Boolean search where users can enter queries
              connecting individual keywords or phrases using the operators
              “AND”, “OR”, “NOT” to broaden or limit their search. The search
              returns all images that contain the keyword in the{' '}
              <b>image text or document title</b>, identified by their unique ID
              and grouped alphabetically by document title. Operators must be
              capitalized.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How do I save documents for future reference?
            </Typography>
            <Typography variant='body1'>
              In order to save a document for future reference, click the
              bookmark icon next to the document title. This will save the
              entire document to users’ personal collections, which can be
              accessed by clicking the user’s icon on the top right of the
              homepage.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              How do I search for documents about specific countries or in
              specific languages?
            </Typography>
            <Typography variant='body1'>
              When searching for languages other than English, users should use
              the following search syntax: name of the language followed by the
              word “translation” with both words enclosed in quotations (e.g.
              “Hindi translation”). All translations are noted in the plain text
              read-out as [X translation], functioning as a tag to capture the
              presence of a language other than English. Searching for a
              specific country name will return any mention of the country
              within the documents.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              What is the timeframe covered by the documents?
            </Typography>
            <Typography variant='body1'>
              Because Frances Haugen smuggled the documents out of Facebook by
              painstakingly taking pictures of them on her smartphone, there is
              no comprehensive metadata associated with them to help provide a
              timeline. Some documents captured in the data include dates, but
              these are dates of internal publication or release, not the date
              when the image was captured.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              Are you altering the data at all?
            </Typography>
            <Typography variant='body1'>
              We have made redactions according to the recommendations of our
              Privacy and Safety board as outlined above, but the original data
              has not been altered and we have not removed any of the original
              images or documents from the collection.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              What data is gathered about people who access the papers?
            </Typography>
            <Typography variant='body1'>
              We gather registered users’ names and email addresses, and monitor
              their searches and tags.
            </Typography>
          </Stack>
          <Stack spacing={2}>
            <Typography
              variant='body1'
              sx={{
                color: theme.palette.white[0],
                fontWeight: 800,
                fontSize: 18,
              }}
            >
              Who is involved in this project?
            </Typography>
            <Typography variant='body1'>
              The project is a collaboration between the Public Interest Tech
              Lab and the Shorenstein Center on Media, Politics, and Public
              Policy at the Harvard Kennedy School of Government.
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
