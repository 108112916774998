import { useEffect, useState, useContext, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import {
  Container,
  Box,
  Button,
  Paper,
  TextField,
  Stack,
  Typography,
  CircularProgress,
  LinearProgress,
  Alert,
  AlertTitle,
} from '@mui/material'
import {
  Routes,
  Route,
  Outlet,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom'
import { motion } from 'framer-motion'
import $axios from '../../tools/axiosWrapper'
import { useForm } from 'react-hook-form'
import { randomUniform } from 'd3-random'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import LinkIcon from '@mui/icons-material/Link'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import _ from 'lodash'
import { useInterval } from 'usehooks-ts'

export const Library2 = ({ user, search, setSearch, results, setResults }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const [load, setLoad] = useState(false)
  const [loadCreate, setLoadCreate] = useState(false)
  const [broke, setBroke] = useState(false)
  const [create, setCreate] = useState([])
  const [count, setCount] = useState(0)
  const [booleaninfo, setBooleaninfo] = useState(false)

  useEffect(() => {
    $axios.get('/collection/list/bookmark').then((response) => {})
  }, [])

  const onSubmit = (data) => {
    setBroke(false)
    setResults(null)
    setSearch(data.search)
    setLoad(true)
    $axios
      .get(`/collection/search/${data.search}`)
      .then((response) => {
        let parse = JSON.parse(response.data.body)
        setResults(parse?.hits?.hits == 0 ? null : parse?.hits?.hits)
        setBroke(parse?.hits?.hits.length == 0 ? 'warn' : false)
      })
      .catch((err) => {
        setBroke('error')
      })
      .finally(() => setLoad(false))
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: { search: '' } })

  useEffect(() => {
    !!location.state && setResults(location.state?.data)
  }, [location])

  const COLUMNS = [
    {
      field: 'imageid',
      headerName: 'image',
      width: 80,
      resizable: true,
      renderCell: (props) => {
        const { hasFocus, value } = props
        return (
          <>
            <div>{value.replace('_w32', '')}</div>
          </>
        )
      },
    },
    {
      field: 'documentid',
      headerName: 'link',
      width: 220,
      resizable: false,
      renderCell: (props) => {
        const { hasFocus, value } = props
        return (
          <>
            <Link
              className={'linktable'}
              to={'doc/' + value.replace('w32', '')}
              state={{ results: results, img: props.id }}
            >
              <LinkIcon />
              <div>{'doc/' + value.replace('w32', '')}</div>
            </Link>
          </>
        )
      },
    },
    {
      field: 'title',
      headerName: 'document title',
      width: 260,
      resizable: true,
    },
    {
      field: 'excerpt',
      headerName: 'excerpt',
      width: 600,
      resizable: true,
    },
  ]

  const ROWS = results?.map((d) => {
    let excerpt = d._source.text

    let index =
      excerpt.indexOf(_.capitalize(search)) !== -1
        ? excerpt.indexOf(_.capitalize(search))
        : excerpt.indexOf(_.lowerCase(search))

    excerpt = index === -1 ? excerpt : excerpt.slice(index, index + 100)

    return {
      id: d._source.imageid,
      excerpt: excerpt,
      ...d._source,
    }
  })

  let placeholder = [
    'facebook AND "social media"',
    'CEO OR C.E.O.',
    'ethiopia AND (protest OR demonstration)',
  ]

  useInterval(() => {
    setCount(count + 1)
  }, 4000)

  const BOOLEANINFO = [
    {
      operator: 'operator',
      definition: 'definition',
      example: 'example',
    },
    {
      operator: 'quotes',
      definition:
        'Placing quotation marks around a search term or phrase limits your search to that exact term or phrase. Without the quotes, your search engine may return all results that contain each separate word.',
      example: '"project manager"',
    },
    {
      operator: 'AND',
      definition:
        'Placing AND between your keywords will return results that only include both or all your keywords. This will limit your search results.',
      example: 'engineer AND "project manager"',
    },
    {
      operator: 'OR',
      definition:
        'Placing OR between your search keywords or terms will broaden your search results by returning all possible combinations of your search terms.',
      example: 'engineer OR "project manager"',
    },
    {
      operator: 'NOT',
      definition:
        'Placing NOT between your search keywords excludes the term after NOT from your search. Any result that has the search option you excluded will not show up in your results.',
      example: 'engineer NOT "project manager"',
    },
    {
      operator: 'Parenthesis',
      definition:
        'Parenthesis can be used to combine other Operators for a more complex search. The keyword(s) within the parenthesis will be given priority. The example given returns results that contain both software engineer and software architect.',
      example: 'software AND (engineer OR architect)',
    },
    {
      operator: 'Asterisk',
      definition:
        "The asterisk can be used for a root word, stem, or truncation. The asterisk is a time-saver so you don't have to write out long, complicated search terms. Most job boards will recognize the asterisk, but not all search engines do.",
      example:
        'admin* = administration, administrator, administer, administered, etc.',
    },
  ]

  return (
    <>
      {user && (
        <Box
          component='form'
          sx={{ minHeight: '280px' }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack sx={{ pt: 10 }} spacing={3}>
            <Stack
              spacing={1}
              sx={{
                width: '500px',
                margin: '0 auto',
              }}
            >
              <TextField
                sx={{
                  width: '500px',
                  margin: '0 auto',
                }}
                id='filled-basic'
                label='image search'
                variant='standard'
                type='text'
                autoComplete='off'
                fullWidth
                onSelect={(e) => setBroke(false)}
                placeholder={placeholder[count % placeholder.length]}
                {...register('search')}
              />
              <Button type={'submit'} disabled={watch('search').length == 0}>
                search
              </Button>
              {load ? (
                <LinearProgress />
              ) : (
                <Box sx={{ height: '4px', width: '100vw' }}></Box>
              )}
              {broke == 'error' && (
                <Alert severity='error' sx={{ background: '#2A1616' }}>
                  <AlertTitle>Too Many Results</AlertTitle>
                  Try something more specific.
                </Alert>
              )}
              {broke == 'warn' && (
                <Alert severity='warning' sx={{ background: '#2A1616' }}>
                  <AlertTitle>No Results</AlertTitle>
                </Alert>
              )}
              <Stack sx={{}} alignItems={'center'}>
                <Stack
                  direction={'row'}
                  alignItems={'flex-start'}
                  onClick={() => setBooleaninfo(!booleaninfo)}
                  sx={{ cursor: 'pointer' }}
                >
                  <Typography
                    variant={'body1'}
                    sx={{
                      textTransform: 'none',
                      color: theme.palette.purple[5],
                      cursor: 'pointer',
                    }}
                  >
                    How to use this search.
                  </Typography>
                  <motion.div
                    style={{ originX: 0.5, originY: 0.4 }}
                    initial={{ rotate: '0deg' }}
                    animate={{ rotate: booleaninfo ? '180deg' : '0deg' }}
                  >
                    <ArrowDropDownIcon sx={{ fill: theme.palette.purple[5] }} />
                  </motion.div>
                </Stack>

                {booleaninfo && (
                  <Stack sx={{ mt: 1, height: '100%' }}>
                    {BOOLEANINFO.map((d) => (
                      <Stack direction={'row'}>
                        <Box
                          sx={{
                            p: 2,
                            width: 150,
                            border: '1px solid ' + theme.palette.white[4],
                          }}
                        >
                          {d.operator}
                        </Box>
                        <Box
                          sx={{
                            p: 2,
                            width: 400,
                            border: '1px solid ' + theme.palette.white[4],
                          }}
                        >
                          {d.definition}
                        </Box>
                        <Box
                          sx={{
                            p: 2,
                            width: 200,
                            border: '1px solid ' + theme.palette.white[4],
                          }}
                        >
                          {d.example}
                        </Box>
                      </Stack>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>

            <div
              className='searchgrid'
              style={{
                width: '80vw',
                marginTop: '50px',
              }}
            >
              {results && (
                <>
                  <Stack direction={'row'} alignItems={'center'}>
                    {/* <Button
                      disabled={create.length == 0}
                      onClick={() => {
                        console.log(search, create)
                        setLoadCreate(true)
                        $axios
                          .post('/tag/docbytag_add', {
                            tag: _.lowerCase(search),
                            imageid: create,
                            referrerdoc: 'none',
                          })
                          .then((response) => {
                            console.log('response', response)
                          })
                          .catch((er) => console.log(er))
                          .finally(() => {
                            setLoadCreate(false)
                            // GET_TAGS()
                          })
                      }}
                    >
                      CREATE DOCUMENT
                    </Button> */}
                    {loadCreate && <CircularProgress size={20} />}
                  </Stack>
                  <Stack spacing={4} sx={{ my: 6 }}>
                    <Typography variant={'h6'}>Search Results:</Typography>
                    <Box sx={{ height: '500px' }}>
                      <DataGridPremium
                        pagination
                        // checkboxSelection
                        onRowSelectionModelChange={(imageids, reason) =>
                          setCreate(imageids)
                        }
                        initialState={{
                          pagination: { paginationModel: { pageSize: 250 } },
                          sorting: {
                            sortModel: [{ field: 'title', sort: 'asc' }],
                          },
                        }}
                        pageSizeOptions={[100, 250, 500]}
                        columns={COLUMNS}
                        rows={ROWS}
                        rowHeight={45}
                      />
                    </Box>
                  </Stack>
                </>
              )}
            </div>
          </Stack>
        </Box>
      )}
    </>
  )
}
